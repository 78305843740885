import { toast } from 'react-toastify';
import { Meta } from '../interfaces/Meta';
import { RSubtitle, SubProvider } from '../interfaces/Subtitle';

export function generateSubFileName(
	meta: Meta,
	sub: RSubtitle,
	season?: number,
	episode?: number
): string {
	return `${meta.name}${
		season && episode
			? `.S${season < 10 ? `0${season}` : season}E${
					episode < 10 ? `0${episode}` : episode
			  }`
			: ''
	}.${sub.iso639_1}.vtt`;
}

export function openSubtitleWindow(sub: RSubtitle): void {
	try {
		const urlObj = new URL(sub.url);
		const path =
			sub.provider !== SubProvider.AIO_SUBTITLE
				? urlObj.searchParams.get('path')
				: sub.url;
		if (!path) {
			throw new Error('Path not found.');
		}

		let newWindowUrl: string = '';
		if (sub.provider === SubProvider.SUBSCENE) {
			newWindowUrl = 'https://subscene.best' + path;
		} else if (sub.provider === SubProvider.OPENSUBTITLES) {
			newWindowUrl = 'https://www.opensubtitles.org' + path;
		} else if (sub.provider === SubProvider.KITSUNEKKO) {
			newWindowUrl = 'https://www.kitsunekko.net' + path;
		} else {
			newWindowUrl = path;
		}
		window.open(newWindowUrl);
	} catch (e: any) {
		toast(e.message, { type: 'error' });
	}
}
